<style lang="scss">
  @import './SiteNavigation.scss';
</style>

<template>
  <nav v-if="siteList.length > 0" class="site-navigation">
    <label v-if="prelabel.length > 0">
      <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5705 6.12903C12.9858 2.52823 11.6068 0 10.002 0C8.39711 0 7.01808 2.52823 6.43341 6.12903H13.5705ZM6.13099 10C6.13099 10.8952 6.17937 11.754 6.26405 12.5806H13.7358C13.8205 11.754 13.8689 10.8952 13.8689 10C13.8689 9.10484 13.8205 8.24597 13.7358 7.41935H6.26405C6.17937 8.24597 6.13099 9.10484 6.13099 10ZM19.2237 6.12903C18.0705 3.39113 15.7358 1.27419 12.8528 0.419355C13.8366 1.78226 14.5141 3.83468 14.8689 6.12903H19.2237ZM7.14711 0.419355C4.26808 1.27419 1.92937 3.39113 0.780179 6.12903H5.13502C5.48582 3.83468 6.16324 1.78226 7.14711 0.419355ZM19.6552 7.41935H15.0302C15.1149 8.26613 15.1632 9.13306 15.1632 10C15.1632 10.8669 15.1149 11.7339 15.0302 12.5806H19.6511C19.8729 11.754 19.9979 10.8952 19.9979 10C19.9979 9.10484 19.8729 8.24597 19.6552 7.41935ZM4.84066 10C4.84066 9.13306 4.88905 8.26613 4.97373 7.41935H0.348727C0.130985 8.24597 0.00195312 9.10484 0.00195312 10C0.00195312 10.8952 0.130985 11.754 0.348727 12.5806H4.9697C4.88905 11.7339 4.84066 10.8669 4.84066 10ZM6.43341 13.871C7.01808 17.4718 8.39711 20 10.002 20C11.6068 20 12.9858 17.4718 13.5705 13.871H6.43341ZM12.8568 19.5806C15.7358 18.7258 18.0745 16.6089 19.2278 13.871H14.8729C14.5181 16.1653 13.8407 18.2177 12.8568 19.5806ZM0.780179 13.871C1.9334 16.6089 4.26808 18.7258 7.15115 19.5806C6.16728 18.2177 5.48986 16.1653 5.13502 13.871H0.780179Z" />
      </svg>
      {{prelabel}}
    </label>
    <ul>
        <li v-for="site in siteList" :key="site.id">
            <a :href="site.url" :title="site.title" :target="site.target" :class="{ selected : site.id === siteSelected }">
                {{site.name}}
                <img v-if="site.logo" :src="site.logo" />
            </a>
        </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: 'SiteNavigation',
  props: {
    prelabel : {
        type: String,
        default: 'Vai al sito di:'
    },
    siteList : [],
    siteSelected : -1
  }
}
</script>