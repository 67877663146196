<style lang="scss">
  @import './SiteLogo.scss';
</style>

<template>
    <div class="site-logo">
        <img :src="siteLogo" :style=" { maxHeight : maxHeight }"/>
    </div>
</template>

<script>
import RTLPlayLogo from '../../assets/rtl-play-logo.svg';
import RTL102_5Logo from '../../assets/rtl-1025-logo.svg';
import RadioZetaLogo from '../../assets/radio-zeta-logo.svg';
import RadiofrecciaLogo from '../../assets/radiofreccia-logo.svg';
import MyPlayLogo from '../../assets/myplay-logo.svg';

export default {
  name: 'SiteLogo',
  computed: {
    siteLogo() {
        switch (location.host){
            case 'www.rtl.it':
            case 'demo.rtl.it':
                return RTL102_5Logo;
            case 'play.rtl.it':
            case 'demo-play.rtl.it':
                return RTLPlayLogo;
            case 'myplay.rtl.it':
            case 'demo-myplay.rtl.it':
                return MyPlayLogo;
            case 'www.radiofreccia.it':
            case 'demo.radiofreccia.it':
                return RadiofrecciaLogo;
            case 'www.radiozeta.it':
            case 'demo.radiozeta.it':
                return RadioZetaLogo;
            default:
                return MyPlayLogo;
        }
        
    },
    maxHeight(){
        switch (location.host){
            case 'www.radiofreccia.it':
            case 'www.radiozeta.it':
            case 'www.rtl.it':
            default:
                return '30px';
            case 'play.rtl.it':
                return '40px';
        }
    }
  }
}
</script>