<style lang="scss">
  @import './MyPlayLogin.scss';
</style>

<template>
    <div class="my-play-login" :class="hideCommunityOnCollapse ? 'hide-on-collapse' : ''">
        <a href="https://myplay.rtl.it/registrati/" target="_blank" class="btn btn-sign-up">Registrati a <i class="my-play-label">MyPlay</i></a>
        <a v-on:click="$emit('toggleProfileModal')" href="#" class="btn btn-sign-in">Accedi <span class="sign-in-label">a <i class="my-play-label">MyPlay</i></span></a>
    </div>
</template>

<script>
export default {
  name: 'MyPlayLogin',
  props: {
    hideCommunityOnCollapse : {
      type: Boolean,
      default: true
    }
  }
}
</script>